import {
  DocumentNode,
  OperationVariables,
  QueryResult,
  useQuery,
} from "@apollo/client";
import {
  CohortFilter,
  CohortSessionFilter,
  CohortSessionTeacherAttendanceFilter,
  EngagementFilter,
  EngagementInstructionalSupportAttendanceFilter,
  EventLogFilter,
  OrganizationFilter,
  SortOrder,
  StudentFilter,
  UserFilter,
} from "@generated/graphql";
import { triggerErrorToast } from "components/shared";
import { Dispatch, useEffect, useMemo, useState } from "react";
import { SortingRule } from "react-table";
import { useDebounce } from "use-debounce";

type WhereFilter<Filter> = {
  and?: WhereFilter<Filter>[] | null;
  or?: WhereFilter<Filter>[] | null;
  not?: WhereFilter<Filter>[] | null;
};

export type TextQueryFilterFunc<Filter> = (textQuery: string) => Filter;

type UseAdvancedSearchProps<
  SortableField,
  Filter extends WhereFilter<Filter>,
> = {
  query: DocumentNode;
  textQuery: string;
  textQueryFilterFunc: TextQueryFilterFunc<Filter>;
  baseFilter?: Filter;
  initialSortBy: SortingRule<SortableField>[];
  initialPageSize: number;
  refetch?: boolean;
  optionalParam?: { [key: string]: string | number | boolean };
};

/**
 * Opinionated all-in-one hook for advanced search queries.
 * Provide it with an advanced search query along with the filter function, offset,
 * limit, and sorting rules, and it will return the query result.
 * It provides debouncing for the textQuery and also offers an optional refetch
 * boolean that can be used to force a refetch of the query.
 *
 * @param
 * - query - The query that you want to make
 * - textQuery - The text query that you want to search for
 * - textQueryFilterFunc - A function that takes in the text query and returns a
 * filter that will be used in the query
 * - baseFilter - A filter that will be used in the query every time
 * - initialSortBy - The sorting rules that you want to use in the query
 * - initialPageSize - The initial page size that you want to use in the query
 * - refetch - When set to true an effect will be triggered to refetch the query
 *
 * @returns
 * - queryResult - The result of the query
 * - pageIndex - The current page index
 * - setPageIndex - A function to set the page index
 * - pageSize - The current page size
 * - setPageSize - A function to set the page size
 * - sortBy - The current sorting rules
 * - setSortBy - A function to set the sorting rules
 */
export function useAdvancedSearch<
  SortableField,
  Filter extends WhereFilter<Filter>,
  Query,
>({
  query,
  textQuery,
  textQueryFilterFunc,
  baseFilter,
  initialSortBy,
  initialPageSize,
  refetch,
  optionalParam,
}: UseAdvancedSearchProps<SortableField, Filter>): {
  queryResult: QueryResult<Query, OperationVariables>;
  pageIndex: number;
  setPageIndex: Dispatch<number>;
  pageSize: number;
  setPageSize: Dispatch<number>;
  sortBy: SortingRule<SortableField>[];
  setSortBy: Dispatch<SortingRule<SortableField>[]>;
} {
  const [debouncedTextQuery] = useDebounce(textQuery, 1500);

  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [sortBy, setSortBy] = useState(initialSortBy);

  const variables = useMemo(() => {
    const baseVariables = {
      filter: filterCombiner(
        textQueryFilterFunc(debouncedTextQuery),
        baseFilter
      ),
      sort: sortingRulesToSchemaSort(sortBy),
      limit: pageSize,
      offset: pageIndex * pageSize,
    };

    if (optionalParam && Object.keys(optionalParam).length > 0) {
      return { ...baseVariables, ...optionalParam };
    }

    return baseVariables;
  }, [
    baseFilter,
    debouncedTextQuery,
    optionalParam,
    pageIndex,
    pageSize,
    sortBy,
    textQueryFilterFunc,
  ]);

  const queryResult = useQuery<Query>(query, {
    variables,
    fetchPolicy: "cache-first",
    onError: (error) =>
      triggerErrorToast({
        message: "Something went wrong with this search.",
        sub: error.message,
      }),
  });

  useEffect(() => {
    if (
      refetch ||
      (debouncedTextQuery === textQuery && debouncedTextQuery.length >= 1)
    ) {
      queryResult.refetch(variables);
    }
  }, [debouncedTextQuery, textQuery, queryResult, variables, refetch]);

  return {
    queryResult,
    pageIndex,
    setPageIndex,
    pageSize,
    setPageSize,
    sortBy,
    setSortBy,
  };
}

/**
 * Combine multiple filters into a single filter using the "and" array.
 * These filters are the ones defined by the advancedSearch schemas on the
 * backend and are used to filter the results of the advancedSearch queries.
 * @param filterA
 * @param filterB
 * @returns
 */
export function filterCombiner<F extends WhereFilter<unknown>>(
  filterA: F,
  filterB?: F
): WhereFilter<F> {
  if (filterB == null) {
    return filterA;
  }

  return { and: [filterB, filterA] };
}

/**
 * This large union type will need to be updated if additional AdvancedSearch
 * type inputs are creatd.
 */
type AnyAdvancedSearchTypeFilter =
  | CohortFilter
  | CohortSessionFilter
  | CohortSessionTeacherAttendanceFilter
  | EngagementFilter
  | EngagementInstructionalSupportAttendanceFilter
  | OrganizationFilter
  | UserFilter
  | StudentFilter
  | EventLogFilter;

/**
 * Use will quickly build a TextQueryFilterFunction. By default returns a filter
 * that uses the 'contains' string filter on any string provided in fields.
 * If you want to use a different filter, pass in a "customFilter" function and
 * have it return the clause that you want to use. That includes deep filtering
 * (such as filtering Cohorts on the name of their Organization).
 *
 * @param fields
 * @returns
 */
export function buildTextQueryFilterFunc<
  Filter extends AnyAdvancedSearchTypeFilter,
  Field extends keyof Filter,
>(
  fields: (
    | Field
    | {
        field: Field;
        customFilter: (textQueryTrimmed: string) => Filter[Field];
      }
  )[]
): TextQueryFilterFunc<Filter> {
  return (textQuery: string) => {
    const textQueryTrimmed = textQuery.trim();

    // If the text query is empty return an empty filter.
    if (textQueryTrimmed.length === 0) return {} as Filter;

    return {
      or: fields.map((field) => {
        if (typeof field === "object") {
          const { field: key, customFilter } = field;
          return {
            [key]: customFilter(textQueryTrimmed),
          } as unknown;
        } else {
          return {
            [field]: { contains: textQueryTrimmed },
          } as unknown;
        }
      }),
    } as Filter; // So long as the input is correct the output will be correct.
  };
}

/**
 * This function converts the SortingRules type used by react-table into the
 * Sort type used by the advancedSearch queries.
 * It relies on the use of the relevant SortableField enum being passed as the
 * id value of a cell in the table.
 */
function sortingRulesToSchemaSort<
  SortableField extends SortingRule<SortableField>["id"],
>(
  sortingRules: SortingRule<SortableField>[]
): { field: SortableField; order?: SortOrder }[] {
  return sortingRules.map((sortingRule) => ({
    field: sortingRule.id as SortableField,
    order:
      sortingRule.desc == null
        ? undefined
        : sortingRule.desc === true
        ? SortOrder.Desc
        : SortOrder.Asc,
  }));
}
