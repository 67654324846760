export type Pixel = number;
export type Percent = number;

const BASE = 10;

export function parseInteger(stringInteger: string): number {
  const parsed = parseInt(stringInteger, BASE);

  if (!Number.isInteger(parsed)) {
    throw new Error("Invalid string. Unable to parse to integer.");
  }

  return parsed;
}

export function parseId(id: string): number {
  // If the id has any non-numeric characters, return NaN.
  if (id.match(/[^0-9]/)) {
    return NaN;
  }
  return parseInteger(id);
}
