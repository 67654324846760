import {
  AccountRole,
  AssignCohortTeachers_CohortFragment,
  AssignEngagementShiftTeachers_EngagementShiftAssignmentFragment,
  AssignEngagementStaff_EngagementFragment,
  CohortAssignmentRole,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  EngagementAssignmentRole,
  RosterRecordStatus,
  RosterRole,
} from "@generated/graphql";
import { SelectAddButtonOption } from "components/shared";

/**
 * Engagement and Cohort StaffAssignment Options. These types are used heavily
 * when choosing Users to assign to staff.
 */
export enum TeacherAssignmentType {
  Engagement = "ENGAGEMENT",
  Cohort = "COHORT",
}

export type EngagementStaffAssignmentOptions = {
  type: TeacherAssignmentType.Engagement;
  engagementAssignmentRole: EngagementAssignmentRole;
};

export type EngagementShiftAssignmentOptions = {
  type: TeacherAssignmentType.Engagement;
  engagementAssignmentRole: EngagementAssignmentRole;
};

export type CohortStaffAssignmentOptions = {
  type: TeacherAssignmentType.Cohort;
  cohortSubject: CohortAssignmentSubject;
  cohortSubSubject: CohortAssignmentSubSubject;
  cohortAssignmentRole: CohortAssignmentRole;
};

export type CommonStaffAssignmentOptions =
  | EngagementStaffAssignmentOptions
  | CohortStaffAssignmentOptions;

// Shortcut types for SelectAddButtonOptions.
export type CohortStaffAssignmentSelectAddOptions =
  SelectAddButtonOption<CohortStaffAssignmentOptions>;
export type EngagementStaffAssignmentSelectAddOptions =
  SelectAddButtonOption<EngagementStaffAssignmentOptions>;
export type CommonStaffAssignmentSelectAddOptions =
  SelectAddButtonOption<CommonStaffAssignmentOptions>;
export type EngagementShiftAssignmentSelectAddOptions =
  SelectAddButtonOption<EngagementShiftAssignmentOptions>;
export type StaffAssignmentOptions = {
  id: string;
  accountRole: AccountRole;
  value: string;
};
export type StaffAddOptions = SelectAddButtonOption<StaffAssignmentOptions>;

export type RosterRoleAssignmentOptions = {
  id: string;
  rosterRole: RosterRole;
  value: string;
};

export type RosterRoleOptions =
  SelectAddButtonOption<RosterRoleAssignmentOptions>;

export type RosterStatusAssignmentOptions = {
  id: string;
  rosterStatus: RosterRecordStatus;
  value: string;
};

export type RosterStatusOptions =
  SelectAddButtonOption<RosterStatusAssignmentOptions>;

export type EngShiftAssignmentWithRole =
  AssignEngagementShiftTeachers_EngagementShiftAssignmentFragment & {
    engagementAssignmentRole: EngagementAssignmentRole;
  };

/**
 * Types using fragments to differentiate between staff being assigned to
 * Engagements or Cohorts. They share the user property, but their staffAssignments
 * have special differences and these types and functions help differentiate
 * between them.
 */
export type AssignCohortStaffAssignment =
  AssignCohortTeachers_CohortFragment["staffAssignments"][number];
export type AssignEngagementStaffAssignment =
  AssignEngagementStaff_EngagementFragment["staffAssignments"][number];

export type AssignCommonStaffAssignment =
  | AssignCohortStaffAssignment
  | AssignEngagementStaffAssignment
  | EngShiftAssignmentWithRole;
