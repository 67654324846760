import { gql, useLazyQuery } from "@apollo/client";
import {
  AccountRole,
  AccountStatus,
  AddUsersInputSearchQuery,
  AddUsersInputSearchQueryVariables,
  AddUsersInput_UserFragment,
  UserFilter,
} from "@generated/graphql";
import { getAccountRoleText } from "@utils/labels";
import { fromJust } from "@utils/types";
import {
  buildTextQueryFilterFunc,
  filterCombiner,
} from "@utils/useAdvancedSearch";
import clsx from "clsx";
import {
  Icon,
  SelectAddButton,
  SelectMenu,
  SelectMenuOption,
} from "components/shared";
import { SearchDropdown, SearchSelectOption } from "components/shared/Inputs";
import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "use-debounce";
import {
  CommonStaffAssignmentOptions,
  CommonStaffAssignmentSelectAddOptions,
} from "./types";

const SEARCH_LIMIT = 15;

const textQueryFilterFunc = buildTextQueryFilterFunc<
  UserFilter,
  keyof UserFilter
>(["fullName", "email"]);

AddUsersInput.fragments = {
  user: gql`
    fragment AddUsersInput_User on User {
      id
      email
      fullName
      accountRole
      accountStatus
    }
  `,
};

const SEARCH_USERS = gql`
  query AddUsersInputSearch($filter: UserFilter!, $limit: Int) {
    advancedSearchUsers(filter: $filter, limit: $limit) {
      items {
        ...AddUsersInput_User
      }
    }
  }
  ${AddUsersInput.fragments.user}
`;

type Props = {
  title?: string;
  userTerm?: string;
  excludedUserIds?: number[];
  includedUserIds?: number[];
  optionsInitialIndex?: number;
  userSearchAccountRoleAllowList?: AccountRole[];
  predefinedUsers?: AddUsersInput_UserFragment[];
  options?: CommonStaffAssignmentSelectAddOptions[];
  onSelect: (user: AddUsersInput_UserFragment | null) => void;
  onClickAdd?: (
    user: AddUsersInput_UserFragment | null,
    assignment: CommonStaffAssignmentOptions | null
  ) => void;
};

export function AddUsersInput({
  options,
  predefinedUsers,
  excludedUserIds,
  includedUserIds,
  userTerm = "User",
  title = "Add Users",
  optionsInitialIndex,
  userSearchAccountRoleAllowList,
  onSelect,
  onClickAdd,
}: Props) {
  const [query, setQuery] = useState<string>("");
  const [debouncedQuery] = useDebounce(query, 600);
  const [userSelection, setUserSelection] =
    useState<AddUsersInput_UserFragment | null>(null);

  const [advancedSearchUsers, { data, loading }] = useLazyQuery<
    AddUsersInputSearchQuery,
    AddUsersInputSearchQueryVariables
  >(SEARCH_USERS, {
    fetchPolicy: "no-cache",
  });

  const predefinedUserOptions: SelectMenuOption[] = useMemo(
    () => [
      {
        id: "NONE_SELECTED",
        value:
          predefinedUsers?.length === 0
            ? `All ${userTerm}s have already been selected...`
            : `Select a ${userTerm}`,
      },
      ...(predefinedUsers !== undefined
        ? predefinedUsers.map(({ id, fullName, accountRole, email }) => ({
            id,
            value: (
              <div>
                {fullName}{" "}
                <span className="italic">
                  ({getAccountRoleText(accountRole, "tiny")})
                </span>
              </div>
            ),
            subValue: email,
          }))
        : []),
    ],
    [predefinedUsers, userTerm]
  );

  useEffect(() => {
    advancedSearchUsers({
      variables: {
        filter: filterCombiner(
          {
            accountStatus: { not: AccountStatus.Inactive },
            accountRole: { in: userSearchAccountRoleAllowList },
            id: { notIn: excludedUserIds, in: includedUserIds },
          },
          textQueryFilterFunc(debouncedQuery)
        ),
        limit: SEARCH_LIMIT,
      },
    });
  }, [
    advancedSearchUsers,
    debouncedQuery,
    excludedUserIds,
    includedUserIds,
    userSearchAccountRoleAllowList,
  ]);

  useEffect(() => {
    if (query !== "") setUserSelection(null);
  }, [query]);

  const onDropdownSelect = (option: SearchSelectOption | null) => {
    if (!option) return setUserSelection(null);
    const id = option.value;
    const users = data?.advancedSearchUsers.items;
    const selectedUser = users?.find((user) => user.id === id);
    setUserSelection(selectedUser ?? null);
    onSelect(selectedUser ?? null);
  };

  const buildOptionLabel = (user: AddUsersInput_UserFragment) => {
    return `${user.fullName} (${getAccountRoleText(
      user.accountRole,
      "tiny"
    )}) - ${user.email}`;
  };

  const dropdownOptions: SearchSelectOption[] = useMemo(() => {
    if (!data?.advancedSearchUsers.items) return [];

    const users = data?.advancedSearchUsers.items;
    return users.map((user) => ({
      value: user.id,
      label: buildOptionLabel(user),
    }));
  }, [data]);

  const selectedOption = useMemo(() => {
    return (
      dropdownOptions.find((option) => option.value === userSelection?.id) ??
      null
    );
  }, [dropdownOptions, userSelection?.id]);

  return (
    <div>
      <label
        htmlFor={"search-users-combobox"}
        className="mb-2 block text-sm font-medium text-gray-700"
      >
        {title}
      </label>

      <div className="flex flex-nowrap">
        <div className="flex-auto gap-x-3">
          {predefinedUsers ? (
            <SelectMenu
              disabled={predefinedUsers?.length === 0}
              options={predefinedUserOptions}
              onSelect={(option) => {
                const nullOption = !option || option.id === "NONE_SELECTED";
                const selectedUser = fromJust(
                  (nullOption ? [] : predefinedUsers ?? []).find(
                    (t) => t.id === option.id
                  )
                );
                setUserSelection(nullOption ? null : selectedUser);
                onSelect(nullOption ? null : selectedUser);
              }}
            />
          ) : (
            <SearchDropdown
              isLoading={loading}
              options={dropdownOptions}
              selection={selectedOption}
              name={"search-teachers-dropdown"}
              placeholder={"Available Teachers"}
              onSelect={onDropdownSelect}
              onInputChange={setQuery}
              onClear={() => {
                setQuery("");
                setUserSelection(null);
              }}
            />
          )}
        </div>

        {onClickAdd &&
          (!options || options.length === 0 ? (
            <button
              type="button"
              disabled={userSelection === null}
              className={clsx(
                "flex flex-center relative",
                "z-10 shadow-sm rounded-md ml-2 border h-[38px] w-[42px]",
                userSelection === null
                  ? "border-gray-300 bg-gray-100"
                  : "bg-blue-500 hover:bg-blue-700 border-blue-800"
              )}
              onClick={() => onClickAdd(userSelection, null)}
            >
              <Icon
                icon="plus"
                size={3}
                color={userSelection === null ? "text-gray-400" : "text-white"}
              />
            </button>
          ) : (
            <div className="z-10 ml-4">
              <SelectAddButton
                options={options}
                initialIndex={optionsInitialIndex}
                outsideOptionSelected={userSelection !== null}
                onAdd={(assignmentOption) => {
                  onClickAdd(userSelection, assignmentOption);
                  setUserSelection(null);
                  setQuery("");
                }}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
