import {
  AccountRole,
  CohortAssignmentRole,
  CohortAssignmentSubSubject,
  CohortAssignmentSubject,
  EngagementAssignmentRole,
  RosterRecordStatus,
  RosterRole,
} from "@generated/graphql";
import {
  getAccountRoleText,
  getCohortSubjectText,
  getEngagementAssignmentRoleText,
  getRosterRecordStatusText,
  getRosterRoleText,
} from "@utils/labels";
import {
  CohortStaffAssignmentSelectAddOptions,
  EngagementStaffAssignmentSelectAddOptions,
  RosterRoleOptions,
  RosterStatusOptions,
  StaffAddOptions,
  TeacherAssignmentType,
} from "./types";

/**
 * Cohort Staff Assignment Options
 */
export const cohortStaffAssignmentSelectAddOptions: CohortStaffAssignmentSelectAddOptions[] =
  [
    {
      id: CohortAssignmentSubject.Math,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Math,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Math),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.Ela,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Ela,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Ela),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.General,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.General,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.General),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.Science,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Science,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Science),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.SocialStudies,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.SocialStudies,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.SocialStudies),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.Eld,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Eld,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Eld),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.Psat,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Psat,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Psat),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.Sat,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Sat,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Sat),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
    {
      id: CohortAssignmentSubject.Act,
      type: TeacherAssignmentType.Cohort,
      cohortSubject: CohortAssignmentSubject.Act,
      cohortSubSubject: CohortAssignmentSubSubject.General,
      value: getCohortSubjectText(CohortAssignmentSubject.Act),
      cohortAssignmentRole: CohortAssignmentRole.PrimaryTeacher,
    },
  ];

/**
 * Engagement Staff Assignment Options
 */

export const engagementTeacherStaffAssignmentSelectAddOptions: EngagementStaffAssignmentSelectAddOptions[] =
  [
    {
      id: EngagementAssignmentRole.MentorTeacher,
      type: TeacherAssignmentType.Engagement,
      engagementAssignmentRole: EngagementAssignmentRole.MentorTeacher,
      value: getEngagementAssignmentRoleText(
        EngagementAssignmentRole.MentorTeacher,
        "long"
      ),
    },
    {
      id: EngagementAssignmentRole.SubstituteTeacher,
      type: TeacherAssignmentType.Engagement,
      engagementAssignmentRole: EngagementAssignmentRole.SubstituteTeacher,
      value: getEngagementAssignmentRoleText(
        EngagementAssignmentRole.SubstituteTeacher,
        "long"
      ),
    },
    {
      id: EngagementAssignmentRole.EngagementManager,
      type: TeacherAssignmentType.Engagement,
      engagementAssignmentRole: EngagementAssignmentRole.EngagementManager,
      value: getEngagementAssignmentRoleText(
        EngagementAssignmentRole.EngagementManager,
        "long"
      ),
    },
  ];

export const engagementFullStaffAssignmentSelectAddOptions: EngagementStaffAssignmentSelectAddOptions[] =
  [...engagementTeacherStaffAssignmentSelectAddOptions];

/**
 * Staff Add Assignment Options
 */

export const staffAccountRoleAddOptions: StaffAddOptions[] = [
  {
    id: AccountRole.TutorTeacher,
    accountRole: AccountRole.TutorTeacher,
    value: getAccountRoleText(AccountRole.TutorTeacher, "long"),
  },
  {
    id: AccountRole.MentorTeacher,
    accountRole: AccountRole.MentorTeacher,
    value: getAccountRoleText(AccountRole.MentorTeacher, "long"),
  },
];

/**
 * Roster Role Assignment Options
 */

export const rosterRoleAddOptions: RosterRoleOptions[] = [
  {
    id: RosterRole.TutorTeacher,
    rosterRole: RosterRole.TutorTeacher,
    value: getRosterRoleText(RosterRole.TutorTeacher),
  },
  {
    id: RosterRole.MentorTeacher,
    rosterRole: RosterRole.MentorTeacher,
    value: getRosterRoleText(RosterRole.MentorTeacher),
  },
  {
    id: RosterRole.SubstituteTeacher,
    rosterRole: RosterRole.SubstituteTeacher,
    value: getRosterRoleText(RosterRole.SubstituteTeacher),
  },
  {
    id: RosterRole.EngagementManager,
    rosterRole: RosterRole.EngagementManager,
    value: getRosterRoleText(RosterRole.EngagementManager),
  },
];

export const rosterStatusOptions: RosterStatusOptions[] = [
  {
    id: RosterRecordStatus.Pending,
    rosterStatus: RosterRecordStatus.Pending,
    value: getRosterRecordStatusText(RosterRecordStatus.Pending),
  },
  {
    id: RosterRecordStatus.Confirmed,
    rosterStatus: RosterRecordStatus.Confirmed,
    value: getRosterRecordStatusText(RosterRecordStatus.Confirmed),
  },
];
