import { DatePicker, DatePickerProps } from "antd";
import clsx from "clsx";
import { FieldError } from "./Error/FieldError";

type Props = DatePickerProps & {
  label?: string;
  required?: boolean;
  className?: string;
  error?: string | null;
  datepickerClassName?: string;
  labelChildren?: React.ReactNode;
};

export const DatePickerInput = ({
  label,
  required,
  error,
  className,
  labelChildren,
  datepickerClassName,
  ...datePickerProps
}: Props) => (
  <div className={clsx("flex flex-col gap-y-1", className)}>
    {label && (
      <div className="flex">
        <label className="block text-sm font-medium text-gray-700 gap-x-1">
          {label}
          {required && (
            <span className="text-gray-500 text-sm font-light ml-1">*</span>
          )}
        </label>
        {labelChildren}
      </div>
    )}
    <DatePicker {...datePickerProps} className={datepickerClassName} />
    {error && <FieldError msg={error} />}
  </div>
);
